import { useState, useEffect, useRef } from "react";
import classNames from "classnames";

import styles from "./index.module.scss";

interface Props extends React.HTMLAttributes<HTMLSelectElement> {
  options: {
    id: string | number;
    description: string | number;
  }[];
  label: string;
  qaLabel?: string;
  color?: "primary" | "secondary";
  variant?: "outline" | "default" | "naked";
  error?: boolean;
  errorMessage?: string;
}

const Select = (props: Props): React.ReactElement => {
  const {
    options,
    onChange,
    label = "",
    variant = "default",
    color = "primary",
    error = false,
    errorMessage = "",
    defaultValue,
    qaLabel,
    ...rest
  } = props;
  const selectRef = useRef<HTMLSelectElement>(null);
  const [value, setValue] = useState("");

  useEffect(() => {
    if (Boolean(defaultValue) === false && selectRef.current) {
      selectRef.current.selectedIndex = 0;
    }
  }, [defaultValue]);

  return (
    <div className={styles.wrapper}>
      <select
        data-qalabel={qaLabel || label}
        ref={selectRef}
        defaultValue={defaultValue}
        {...rest}
        data-value={value}
        onChange={(evt: React.ChangeEvent<HTMLSelectElement>): void => {
          onChange?.(evt);
          setValue(evt.currentTarget.value);
        }}
        className={classNames(styles.select, {
          [styles.default]: variant === "default",
          [styles.outline]: variant === "outline",
          [styles.naked]: variant === "naked",
          [styles.primary]: color === "primary",
          [styles.secondary]: color === "secondary",
          [styles.error]: error,
        })}
      >
        <option data-qalabel={`${qaLabel || label}-`} value=""></option>
        {options.map((item) => (
          <option
            data-qalabel={`${qaLabel || label}-${item.description}`}
            key={item.id}
            value={item.id}
          >
            {item.description}
          </option>
        ))}
      </select>
      <label
        className={classNames(styles.label, {
          [styles.primary]: color === "primary",
          [styles.secondary]: color === "secondary",
          [styles.default]: variant === "default",
          [styles.outline]: variant === "outline",
          [styles.error]: error,
        })}
      >
        {label}
      </label>
      {error ? (
        <span className={styles.errorMessage}>{errorMessage}</span>
      ) : null}
    </div>
  );
};

export default Select;
