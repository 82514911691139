import React from "react";
import routes from "@lc/lib/routes";

import DentistryIcon from "./icons/dentistry";
import PhysioterapyIcon from "./icons/physiotherapy";
import NutritionIcon from "./icons/nutrition";
import MedicIcon from "./icons/medic";
import PsychologyIcon from "./icons/psychology";
import PhonoIcon from "./icons/phono";

export type ExpertiseListProps = {
  name: string;
  id: string;
  Icon: () => React.ReactElement;
  link: string;
};

const expertiseList: ExpertiseListProps[] = [
  {
    name: "Odontologia",
    id: "12098876-d1c6-427d-9e9f-2ef04b900d12",
    Icon: DentistryIcon,
    link: routes.public.searchByExpertise("odontologia"),
  },
  {
    name: "Médico",
    id: "38d25e1e-cd95-40cf-ac58-bf3b5be132b3",
    Icon: MedicIcon,
    link: routes.public.searchByExpertise("medico"),
  },
  {
    name: "Psicologia",
    id: "d6be3953-5a1d-46f3-8da0-746a2551ab48",
    Icon: PsychologyIcon,
    link: routes.public.searchByExpertise("psicologia"),
  },
  {
    name: "Fisioterapia",
    id: "559c6a61-9182-46bd-8000-ed032b1351cf",
    Icon: PhysioterapyIcon,
    link: routes.public.searchByExpertise("fisioterapia"),
  },
  {
    name: "Fonoaudiologia",
    id: "6242f165-b69e-4f0d-b019-8641e71abe9c",
    Icon: PhonoIcon,
    link: routes.public.searchByExpertise("fonoaudiologia"),
  },
  {
    name: "Nutrição",
    id: "a579580b-273f-482f-9e51-3c391801e1d8",
    Icon: NutritionIcon,
    link: routes.public.searchByExpertise("nutricao"),
  },
];

export default expertiseList;
