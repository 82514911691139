const Icon = (): React.ReactElement => (
  <svg
    width={31}
    height={26}
    viewBox="0 0 31 26"
    stroke="#34BA92"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.37 14.66H7.06a.544.544 0 00.487-.295l1.419-2.802c.211-.418.838-.383.998.056l2.796 7.642c.168.458.833.469 1.017.016L19.16 5.97c.174-.431.799-.448.998-.027l3.98 8.411a.544.544 0 00.494.306h1.999M1 8.353c0-1.95.8-3.82 2.225-5.2A7.724 7.724 0 018.595 1c2.014 0 3.946.775 5.37 2.154L15.5 4.639l1.534-1.485A7.724 7.724 0 0122.404 1c2.015 0 3.947.775 5.372 2.154A7.236 7.236 0 0130 8.353c0 1.95-.8 3.82-2.224 5.199l-11.509 11.14a1.11 1.11 0 01-1.534 0l-3.836-3.713-.034-.032-7.638-7.395A7.235 7.235 0 011 8.352z"
      fill="none"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
