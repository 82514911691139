const Icon = (): React.ReactElement => (
  <svg
    width={30}
    height={30}
    viewBox="0 0 30 30"
    stroke="#34BA92"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.862 15.21h6.759c1.066 0 1.93.893 1.93 1.995v9.8c0 1.102-.864 1.995-1.93 1.995-1.067 0-1.931-.893-1.931-1.995V23.61h-.534a2.039 2.039 0 01-.503 1.853 1.888 1.888 0 01-2.73.025l-.083-.084a3.432 3.432 0 01-.978-2.408c0-1.865 1.463-3.376 3.268-3.376h1.56v-.42H4.38C2.512 19.2 1 17.637 1 15.709V14.29C1 12.363 2.513 10.8 4.38 10.8h5.31v-.42H8.13c-1.805 0-3.268-1.511-3.268-3.376 0-.906.352-1.773.978-2.408l.083-.084a1.888 1.888 0 012.73.025c.482.506.648 1.21.503 1.853h.534V2.995C9.69 1.893 10.554 1 11.62 1c1.067 0 1.932.893 1.932 1.995v9.8c0 1.102-.865 1.995-1.931 1.995H4.862v.42zM25.138 15.21h-6.759c-1.066 0-1.93.893-1.93 1.995v9.8c0 1.102.864 1.995 1.93 1.995 1.067 0 1.931-.893 1.931-1.995V23.61h.534a2.038 2.038 0 00.503 1.853c.747.786 1.97.797 2.73.025l.083-.084a3.432 3.432 0 00.978-2.408c0-1.865-1.463-3.376-3.268-3.376h-1.56v-.42h5.31c1.867 0 3.38-1.563 3.38-3.491V14.29c0-1.928-1.513-3.491-3.38-3.491h-5.31v-.42h1.56c1.805 0 3.268-1.511 3.268-3.376 0-.906-.352-1.773-.978-2.408l-.083-.084a1.887 1.887 0 00-2.73.025 2.039 2.039 0 00-.503 1.853h-.534V2.995C20.31 1.893 19.446 1 18.38 1c-1.067 0-1.932.893-1.932 1.995v9.8c0 1.102.865 1.995 1.931 1.995h6.759v.42z"
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </svg>
);

export default Icon;
