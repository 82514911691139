import React from "react";
import classNames from "classnames";

import expertiseList from "@lc/lib/helpers/expertiseList";

import Link from "@lc/ui/components/Link";
import Text from "@lc/ui/components/Text";

import styles from "./index.module.scss";

const ExpertiseList = ({
  modal = false,
}: {
  modal?: boolean;
}): React.ReactElement => (
  <>
    {modal ? (
      <Text styleAs="h5" element="h5">
        Tipos de consultório
      </Text>
    ) : (
      <Text styleAs="h5" styleAsOnLg="h2" element="h5">
        Tipos de <b>consultório</b>
      </Text>
    )}
    <br />
    <ul className={styles.expertiseList}>
      {expertiseList?.map((item) => {
        const Icon = item.Icon;
        return (
          <li key={item.name}>
            <Link
              naked
              href={item.link}
              textProps={{
                styleAs: "h6",
                styleAsOnLg: "h4",
                element: "span",
              }}
            >
              <span
                className={classNames(styles.expertiseText, {
                  [styles.modal]: modal,
                })}
              >
                <Icon />
                {item.name}
              </span>
            </Link>
          </li>
        );
      })}
    </ul>
  </>
);

export default ExpertiseList;
