const Icon = (): React.ReactElement => (
  <svg
    width={25}
    height={26}
    viewBox="0 0 25 26"
    stroke="#34BA92"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.1 1.913C5.28 3.131 3.042 5.467 1.874 8.408a12.483 12.483 0 000 9.184c1.168 2.94 3.406 5.277 6.224 6.495m0-22.174a11.058 11.058 0 018.802 0m-8.802 0a11.463 11.463 0 00-3.544 2.412 9.067 9.067 0 001.425 2.39C7.488 8.532 9 10.597 9 13s-1.512 4.468-3.02 6.285a9.068 9.068 0 00-1.426 2.39A11.464 11.464 0 008.1 24.087m0 0a11.058 11.058 0 008.802 0m0 0c2.818-1.218 5.056-3.555 6.224-6.495a12.483 12.483 0 000-9.184c-1.168-2.94-3.406-5.277-6.224-6.495m0 22.174a11.464 11.464 0 003.544-2.412 9.067 9.067 0 00-1.425-2.39C17.512 17.468 16 15.403 16 13s1.512-4.469 3.02-6.285a9.066 9.066 0 001.425-2.39 11.463 11.463 0 00-3.544-2.412"
      fill="none"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
