const Icon = (): React.ReactElement => (
  <svg
    width={36}
    height={29}
    viewBox="-4 0 36 29"
    stroke="#34BA92"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width={5.943}
      height={1.434}
      rx={0.717}
      transform="matrix(.95422 -.2991 .25308 .96744 27.55 16.406)"
    />
    <rect
      width={5.836}
      height={1.459}
      rx={0.73}
      transform="rotate(21.258 -34.27 84.073) skewX(.097)"
    />
    <rect
      width={5.91}
      height={1.442}
      rx={0.721}
      transform="matrix(.6307 .77603 -.74824 .66343 27.205 19.567)"
    />
    <path
      d="M20.106 11.195l-.942-.348a.286.286 0 01.032-.548l.91-.224M12.146 1a11.236 11.236 0 00-7.719 3.06l-.165.155A10.378 10.378 0 001 11.755c0 2.846 1.178 5.57 3.262 7.54l.165.156c.07.066.14.131.212.196v7.457c0 .495.407.896.91.896h10.008c.502 0 .91-.401.91-.896v-4.145l2.256.202c1.512.135 2.846-.972 2.967-2.464l.317-3.9h1.172c1.201 0 2.073-1.126 1.755-2.266l-1.582-5.69a.145.145 0 00-.05-.076 10.427 10.427 0 00-2.82-4.55l-.164-.156A11.236 11.236 0 0012.598 1h-.452z"
      fill="none"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
