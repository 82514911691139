import classNames from "classnames";
import React from "react";

import styles from "./divisor.module.scss";

type Props = {
  noTopMargin?: boolean;
  noBottomMargin?: boolean;
};
const Divisor = ({ noTopMargin = false, noBottomMargin = false }: Props) => (
  <hr
    className={classNames(styles.divisor, {
      [styles.noBottomMargin]: noTopMargin,
      [styles.noBottomMargin]: noBottomMargin,
    })}
  />
);

export default Divisor;
