const Icon = (): React.ReactElement => (
  <svg
    width={20}
    height={24}
    viewBox="0 0 20 24"
    stroke="#34BA92"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4 22.58c-.12 0-.21-.03-.27-.12-.24-.27-.21-.9-.12-2.19.12-1.74.3-4.11-.66-5.13-.51-.54-.99-.81-1.47-.84-.45 0-.96.3-1.44.84-.96 1.02-.78 3.39-.66 5.13.09 1.29.12 1.92-.12 2.19-.06.09-.18.12-.27.12-2.25 0-2.4-2.25-2.49-3.72v-.09c-.12-2.07-.51-3.96-1.11-5.61-.18-.45-.39-.93-.6-1.38-.15-.3-.3-.6-.42-.93l-.27-.51C1.6 8.27.58 5.9 1.03 3.59c.09-.45.24-.93.45-1.35C2.38.35 4.39.47 5.83.74c.48.09.96.21 1.47.33 1.47.36 3.03.72 4.38.24C13 .83 14.86.35 16.36.65c.9.18 1.56.75 1.98 1.59.21.42.36.87.45 1.35.45 2.37-.51 4.59-1.47 6.72-.33.78-.66 1.53-.93 2.25-.12.36-.24.75-.39 1.11-.18.57-.39 1.17-.6 1.74-.33.93-.39 2.07-.45 3.06l-.03.33c-.12 1.53-.27 3.78-2.52 3.78zM4.39 1.07c-1.26 0-2.07.45-2.52 1.41-.18.39-.33.81-.39 1.23-.42 2.19.57 4.47 1.44 6.48l.21.51c.12.3.27.6.42.9.21.45.45.93.63 1.41.63 1.68 1.02 3.63 1.14 5.73v.09c.09 1.8.33 3.3 2.01 3.3.12-.21.03-1.11 0-1.83-.12-1.8-.3-4.29.78-5.46.6-.63 1.2-.96 1.77-.96.6 0 1.2.33 1.8.96 1.08 1.17.9 3.66.78 5.46-.06.72-.12 1.62 0 1.83 1.68-.03 1.89-1.5 2.01-3.3l.03-.33c.06-1.05.12-2.22.48-3.21.21-.57.39-1.14.6-1.71.12-.36.24-.75.39-1.11.27-.75.6-1.53.93-2.28.93-2.07 1.86-4.23 1.44-6.48-.09-.42-.21-.84-.39-1.23-.33-.72-.9-1.17-1.65-1.35-1.53-.33-3.48.24-4.5.6-1.5.54-3.09.15-4.65-.24-.51-.12-.96-.24-1.44-.33-.48-.06-.93-.09-1.32-.09zm11.79 7.68l-.42-.15c.42-1.38.54-2.55.3-3.66-.09-.45-.21-.87-.42-1.26-.33-.72-.93-1.2-1.71-1.38a1.77 1.77 0 00-.42-.06L11.41 2l2.13-.21c.54-.06 1.02-.03 1.44.06.72.15 1.29.6 1.62 1.32.15.36.27.72.36 1.08.3 1.56-.18 3.09-.78 4.5zm-.99-6.42c.36.27.66.66.9 1.11.21.42.36.9.45 1.38.03.21.06.39.09.6.03-.36 0-.75-.09-1.14-.06-.33-.18-.66-.33-.99-.24-.45-.57-.78-1.02-.96z"
      fill="none"
    />
  </svg>
);
export default Icon;
