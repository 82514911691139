const Icon = (): React.ReactElement => (
  <svg
    width={24}
    height={28}
    viewBox="0 0 24 28"
    stroke="#34BA92"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.393 9.467a5.882 5.882 0 00-5.45-.181l-.293.14A7.311 7.311 0 0012 10.52a7.311 7.311 0 00-1.65-1.093l-.292-.141a5.877 5.877 0 00-5.451.181 5.954 5.954 0 00-1.988 1.834l-.184.27a7.438 7.438 0 00-1.111 2.576l-.094.425a9.749 9.749 0 00-.175 3.139l.056.528c.108 1.012.37 2 .777 2.93l.213.487a9.656 9.656 0 001.704 2.63l.291.32a7.323 7.323 0 002.237 1.67l.292.14A5.877 5.877 0 0012 26.278a5.881 5.881 0 005.375.138l.291-.14a7.326 7.326 0 002.238-1.67l.29-.32a9.656 9.656 0 001.705-2.63l.213-.486c.407-.93.669-1.92.777-2.93l.056-.53a9.75 9.75 0 00-.175-3.139l-.094-.424a7.437 7.437 0 00-1.111-2.577l-.184-.27a5.955 5.955 0 00-1.988-1.833zM16.92 1.156c.088.322.143.653.165.988l.004.064a4.793 4.793 0 01-.243 1.837l-.03.09a4.727 4.727 0 01-.916 1.59l-.046.053c-.407.47-.911.859-1.48 1.142a4.94 4.94 0 01-1.875.508 4.766 4.766 0 01-1.518-.142 4.77 4.77 0 01-.17-1.6l.004-.064a4.928 4.928 0 01.489-1.846l.028-.057c.27-.554.65-1.051 1.12-1.468l.07-.062a4.652 4.652 0 011.579-.915l.066-.022a4.731 4.731 0 011.847-.241c.306.02.61.069.905.145z"
      fill="none"
      strokeWidth={1.5}
      strokeLinecap="square"
    />
  </svg>
);

export default Icon;
