import { ReactElement, FormEvent, useState, useEffect } from "react";
import Router, { useRouter } from "next/router";

import expertiseList from "@lc/lib/helpers/expertiseList";
import useFetchSearch from "@lc/lib/hooks/useFetchSearch";

import Button from "@lc/ui/components/Button";
import Input from "@lc/ui/components/Form/Input";
import Select from "@lc/ui/components/Form/Select";
import { OptionsType } from "@lc/ui/components/Form/Suggestions";

import styles from "./index.module.scss";
type SearchFormProps = {
  qaLabel?: string;
};

const SearchForm = ({ qaLabel = "" }: SearchFormProps): ReactElement => {
  const { getPredictions, predictions, getSearchUrl } = useFetchSearch();
  const [place, setPlace] = useState<OptionsType>();
  const [typed, setTyped] = useState("");
  const [expertise, setExpertise] = useState("");
  const [loading, setLoading] = useState(false);
  const router = useRouter();

  const handleOnChange = ({
    currentTarget: { value },
  }: FormEvent<HTMLInputElement>): void => {
    setTyped(value);
    getPredictions(value);
  };

  const onChoose = (place: OptionsType) => {
    setPlace(place);
    setTyped(place.description);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (place || expertise) {
      setLoading(true);
      const searchedExp = expertiseList.find((ex) => ex.id === expertise);

      const url = await getSearchUrl({
        place,
        expertise: searchedExp?.name,
      });
      Router.push(url);
    }
  };

  useEffect(() => {
    const handleRouteChange = () => {
      setLoading(false);
    };
    router.events.on("routeChangeStart", handleRouteChange);

    return () => router.events.off("routeChangeStart", handleRouteChange);
  }, [router]);

  return (
    <form
      data-qalabel={qaLabel}
      className={styles.searchForm}
      onSubmit={onSubmit}
    >
      <Select
        label="Qual tipo de consultório?"
        color="secondary"
        options={expertiseList.map((item) => ({
          ...item,
          description: item.name,
        }))}
        variant="outline"
        onChange={({
          currentTarget: { value },
        }: FormEvent<HTMLSelectElement>) => setExpertise(value)}
      />

      <Input
        label="Onde você quer atender?"
        color="secondary"
        variant="outline"
        onChoose={onChoose}
        onChange={handleOnChange}
        options={predictions}
        optionsOffset={40}
        value={typed}
      />
      <Button
        loading={loading}
        label="Buscar consultórios"
        color="secondary"
        fullWidth
      />
    </form>
  );
};

export default SearchForm;
